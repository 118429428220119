import { Link as GatsbyLink } from 'gatsby'
import find from 'lodash/find'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'
import Select, { components } from 'react-select'
import { css, theme } from 'twin.macro'
import { usePageContext } from '../context/page-context'
import { ReactComponent as Indicator } from '../images/icon-caret-down-cyan.svg'

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Indicator />
  </components.DropdownIndicator>
)

const Option = ({ children, ...props }) => {
  const { data } = props  
  const internal = /^\/(?!\/)/
  if (internal.test(data.link)) {
    return (
      <GatsbyLink aria-label={`Change site language to ${data.fullLabel}`} to={data.link}>
        <components.Option {...props}>{children}</components.Option>
      </GatsbyLink>
    )
  } else {
    return (
      <div>
        <a href={data.link}>
          <components.Option {...props}>{children}</components.Option>
        </a>
      </div>
    )
  }
}

Option.propTypes = {
  children: PropTypes.any,
  data: PropTypes.shape({
    fullLabel: PropTypes.string,
    link: PropTypes.string,
  }),
}

const selectorStyle = () => ({
  control: (provided, { isDisabled }) => ({
    ...provided,
    width: '100px',
    padding: '10px',
    border: 'none',
    borderRadius: '0px',
    color: theme`colors.blue-dark`,
    backgroundColor: isDisabled ? theme`colors.smoke-grey` : theme`colors.primary.100`,
    boxShadow: 'none',
    '@media (min-width: 1024px)': {
      ...provided['@media (min-width: 1024px)'],
      padding: '8px',
      minWidth: '66px',
      //minWidth: '120px', //When label is the fullName, i.e. English
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'transform 0.3s ease-in-out',
    transform: state.selectProps.menuIsOpen && 'scaleY(-1)',
  }),
  container: (provided) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '500',
    '@media (min-width: 1024px)': {
      ...provided['@media (min-width: 1024px)'],
      fontWeight: '500',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided, { isDisabled }) => ({
    ...provided,
    display: isDisabled ? 'none' : 'flex',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '5px',
    border: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
    transition: 'all 0.3s ease-in-out',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme`colors.blue-dark`,
    width: '100%',
    opacity: '0.7',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    display: state.isSelected ? 'none' : 'block',
    padding: '8px 16px',
    '@media (min-width: 1024px)': {
      ...provided['@media (min-width: 1024px)'],
      padding: '8px 16px',
    },
    color: theme`colors.blue-dark`,
    backgroundColor: theme`colors.primary.100`,
    '&:hover': {
      backgroundColor: theme`colors.select-hover`,
    },
    border: 'none',
    borderRadius: '0px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: '8px 16px',
    '@media (min-width: 1024px)': {
      ...provided['@media (min-width: 1024px)'],
      padding: '8px 16px',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 8px',
    color: theme`colors.blue-dark`,
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    color: theme`colors.primary.500`,
    textOverflow: 'initial',
  }),
})

const menuStyle = css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  margin-top: 0;
  animation: fadeIn 0.2s ease-in-out;
`

const Menu = (props) => {
  const { children } = props

  return (
    <components.Menu {...props} css={menuStyle}>
      {children}
    </components.Menu>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
}

const LanguagePicker = ({ style, url }) => {
  const { originalPath, locales, locale } = usePageContext()
  const options = map(locales, (l) => ({
    label: (l.label == 'JP' ? '日本語' : l.label),    
    fullLabel: l.fullName,
    value: l.lang,
    link: url,
    //link: l.lang === 'jp' ? url : `/${l.lang}${originalPath}`, 
  }))

  const defaultValue = options.filter((opt)=> opt.label!='EN')
  
  return (
    <Select
      name="language-selector"
      isSearchable={false}
      components={{ DropdownIndicator, Option, Menu }}
      styles={selectorStyle()}
      css={[style]}
      options={options}
      value={defaultValue}
    />
  )
}

LanguagePicker.propTypes = {
  style: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.string,
}

export default LanguagePicker