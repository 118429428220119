import Lottie from 'lottie-web'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import tw, { css, theme } from 'twin.macro'
import { ReactComponent as Caret } from '../images/icon-caret-down.svg'
import { global } from '../styles/global'
import { max, min } from '../utils/breakpoints'
import { LinkType, StyleType, SVGType } from '../utils/prop-types'
import Image from './image'
import Link from './link'
import MainNav from './main-nav'
import Text from './text'
import LanguagePicker from './language-picker'

const SideDropdown = ({ link, style }) => (
  <div
    css={[
      css`
        ${tw`relative h-full`}
        &:hover {
          button + div {
            ${tw`opacity-100 pointer-events-auto`}
          }
          svg {
            ${tw`transform -rotate-180`}
          }
        }
      `,
      style,
    ]}
  >
    <button type="button" css={[tw`flex items-center h-full`, global`base.hover-underline`()]}>
      <Text content={link.label} textType="subtitle" style={tw`font-medium`} />
      {link.subLinks && link.subLinks.length ? (
        <Caret css={tw`ml-5 transition-transform duration-300 ease-in-out fill-primary`} />
      ) : null}
    </button>
    {link.subLinks && link.subLinks.length ? (
      <div
        css={tw`absolute px-8 py-6 bg-white w-77.5 -left-8 top-desktop-header opacity-0 pointer-events-none transition-opacity duration-300 ease-in-out`}
      >
        <ul>
          {link.subLinks.map((subLink) => (
            <li key={subLink.label}>
              <Link to={subLink.link} style={tw`flex flex-row items-center`}>
                <Image image={subLink.image} style={tw`mr-4 h-7 w-7`} />
                <Text content={subLink.label} textType="subtitle" style={tw`font-medium`} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    ) : undefined}
  </div>
)

SideDropdown.propTypes = {
  link: LinkType,
  style: StyleType,
}

const Header = ({
  logoWhiteAccent,
  logoCyanAccent,
  callToAction,
  navItems,
  socialMedia,
  socialMediaLabel,
  copyright,
  customerArea,
  headerTransition,
  headerTransitionColor,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [detectScroll, setDetectScroll] = useState(false)
  const [detectHover, setDetectHover] = useState(false)
  const logoDiv = useRef()

  const handleHover = (hover) => setDetectHover(hover)
  const handleScroll = debounce(() => {
    if (window.pageYOffset === 0) {
      setDetectScroll(false)
    } else {
      setDetectScroll(true)
    }
  }, 50)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true })
    }
  }, [])

  const loadAnimation = (json) => {
    return Lottie.loadAnimation({
      container: logoDiv.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: json,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMax meet',
      },
    })
  }

  useEffect(() => {
    const displayWhiteLogo =
      !detectScroll &&
      !detectHover &&
      headerTransition &&
      headerTransitionColor === 'white' &&
      !isOpened

    const isMobile =
      parseFloat(document.documentElement.style.getPropertyValue('--vw').slice(0, -2)) < 10.24

    const selectedLogo = (() => {
      if (displayWhiteLogo) return logoWhiteAccent
      if (isMobile && isOpened) return logoWhiteAccent
      return logoCyanAccent
    })()

    const animation = loadAnimation(selectedLogo)
    return () => {
      animation.destroy()
    }
  }, [detectScroll, detectHover, headerTransition, headerTransitionColor, isOpened])


  return (
    <header
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      css={[
        css`
          ${tw`fixed inset-x-0 top-0 z-20 transition-all duration-300 ease-in-out bg-transparent h-header-mobile`}
          ${max.lg} {
            background: linear-gradient(
              to left,
              ${theme`colors.secondary.500`} 50%,
              ${headerTransition && !detectScroll  && !detectHover  ? 'transparent 50%' : 'white 50%'}
            );
            background-size: 200% 100%;
            background-position: left bottom;
          }
          ${min.lg} {
            ${tw`h-header-default`}
            &:hover {
              ${tw`bg-white`}
            }
          }
        `,
        headerTransition && !detectScroll  && !detectHover  ? tw`bg-transparent` : tw`bg-white`,
        isOpened &&
        css`
            ${max.lg} {
              ${tw`bg-right-bottom`}
            }
          `,
      ]}
    >
      <div css={[global`layout.container`, global`layout.grid`, tw`items-center h-full`]}>
        <Link to="/" style={tw`col-span-2`} onClick={() => setIsOpened(false)}>
          <div ref={logoDiv} css={tw`h-logo-mobile lg:h-logo-default`} />
        </Link>
        <div
          css={tw`col-start-4 col-end-5 md:(col-start-8 col-end-9) lg:(col-start-3 col-end-10 h-full px-4) xl:(col-start-4 col-end-12 px-0)`}
        >
          <MainNav
            navItems={navItems}
            socialMedia={socialMedia}
            socialMediaLabel={socialMediaLabel}
            customerArea={customerArea}
            callToAction={callToAction}
            copyright={copyright}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            handleHover={handleHover}
            headerTransition={headerTransition}
            detectHover={detectHover}
            headerTransitionColor={headerTransitionColor}
          />
        </div>
        <div
          css={[
            tw`hidden lg:(flex justify-end items-center h-full col-start-10 col-end-13)
            xl:(col-start-12 col-end-17)`,
          ]}>
          <LanguagePicker style={[tw`ml-4`]} url="https://bolttech.io"/>
        </div>
      </div>
      <div
        css={[
          tw`absolute inset-x-0 bottom-0 block h-px -z-10 lg:(hover:bg-line-grey)`,
          isOpened && tw`hidden lg:(bg-secondary-500)`,
          detectHover && tw`lg:(bg-line-grey)`,
          detectScroll && tw`bg-line-grey`,
        ]}
      />
    </header>
  )
}

Header.propTypes = {
  logo: SVGType,
  callToAction: LinkType,
  navItems: PropTypes.arrayOf(PropTypes.object),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  socialMediaLabel: PropTypes.string,
  copyright: PropTypes.string,
  customerArea: LinkType,
  headerTransition: PropTypes.bool,
  headerTransitionColor: PropTypes.oneOf(['white', 'navy']),
}

export default Header
